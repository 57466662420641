import React from 'react';
import { Form } from 'react-bootstrap';
import { Controller, UseFormMethods } from 'react-hook-form';
import { useIntl } from 'react-intl';
import NumberFormat from 'react-number-format';

type PackagePriceProps = {
    methods: UseFormMethods;
    required: boolean;
    decimalPlaces: number;
};

const PackagePrice = ({ methods, required, decimalPlaces }: PackagePriceProps) => {
    const Intl = useIntl();
    const {
        control,
        formState: { errors },
    } = methods;

    return (
        <Form.Group>
            <Controller
                control={control}
                name="customPackagePrice"
                rules={{
                    required: required && Intl.formatMessage({ id: 'FORM.ERROR.REQUIRED' }),
                    pattern: {
                        value: new RegExp(`^-?\\d+(\\.\\d{1,${decimalPlaces}})?$`),
                        message: Intl.formatMessage(
                            { id: 'CUSTOMERS.MODAL.APVINSTORE.ERROR.DECIMAL' },
                            { decimalPlaces },
                        ),
                    },
                }}
                render={({ onChange }) => (
                    <NumberFormat
                        onValueChange={(values) => onChange(values.value)}
                        className={`form-control ${errors.customPackagePrice ? 'is-invalid' : ''}`}
                        thousandSeparator=" "
                        placeholder="0 €"
                        suffix={`€ ${Intl.formatMessage({
                            id: 'CUSTOMERS.MODAL.APVINSTORE.SUMMARY.PACKAGE_PRICE_HT',
                        })}`}
                        displayType="input"
                    />
                )}
            />
        </Form.Group>
    );
};

export default PackagePrice;
