import React, { useContext, useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';

import { OrderInStore } from '@app/crud/apv/order.type';
import { addOneCustomPackage, addOnePackage, deteleOnePackage } from '@app/crud/apv/package.crud';

import { useModal } from '@app/hooks';
import { Package } from '@app/pages/apv/orders/Order';

import Loader from '@app/partials/content/Loader';
import toast from '@app/partials/content/Toast';

import APVInStoreContext from '../APVInStoreContext';

import APVInStorePackageSelect from './APVInStoreAddPackage/APVInStorePackageSelect';
import APVInStoreAddPackageCustom from './APVInStoreAddPackageCustom';

const APVInStorePackages = () => {
    const Intl = useIntl();
    const methods = useFormContext();
    const { getValues } = methods;
    const { stepperState, order, setOrder } = useContext(APVInStoreContext);
    const [_show, _toggle, params] = useModal(false, 'apv_in_store') as [
        boolean,
        (show: boolean) => void,
        { order: OrderInStore },
    ];
    const [isLoading, setIsLoading] = useState(false);
    const [showAddPackageCustom, setShowAddPackageCustom] = useState(false);

    useEffect(() => {
        if (params?.order) {
            setOrder(params.order);
        }
    }, [params?.order]);

    const handlePackageSelect = async (pkg: Package) => {
        try {
            if (!pkg) {
                toast({
                    variant: 'danger',
                    message: Intl.formatMessage({ id: 'TRANSLATOR.ERROR.NULL_PACKAGE' }),
                });
                return;
            }
            setIsLoading(true);
            if (!order?.orderPackages.some((p) => p.packageId === pkg.packageId)) {
                const response = await addOnePackage(getValues('id'), pkg.packageId);
                if (response?.result?.id) {
                    setOrder(response?.result);
                    toast({
                        variant: 'success',
                        message: Intl.formatMessage({ id: 'CUSTOMER.MODAL.APVINSTORE.CONFIRM.ADD.PACKAGE' }),
                    });
                } else {
                    toast({
                        variant: 'danger',
                        message: Intl.formatMessage({ id: 'TRANSLATOR.ERROR' }),
                    });
                }
            }
        } finally {
            setIsLoading(false);
        }
    };

    const handleAddingCustomPackage = async (pkg: Partial<Package>) => {
        try {
            if (!pkg) {
                toast({
                    variant: 'danger',
                    message: Intl.formatMessage({ id: 'TRANSLATOR.ERROR.NULL_PACKAGE' }),
                });
                return;
            }
            setIsLoading(true);
            if (!order?.orderPackages.some((p) => p.name === pkg.name)) {
                const response = await addOneCustomPackage({
                    orderId: getValues('id'),
                    packageSlug: 'forfait_libre',
                    name: pkg.name,
                    price: pkg.price,
                    duration: pkg.mecaplanning.duration,
                });
                if (response?.result?.id) {
                    setOrder(response?.result);
                    toast({
                        variant: 'success',
                        message: Intl.formatMessage({ id: 'CUSTOMER.MODAL.APVINSTORE.CONFIRM.ADD.PACKAGE' }),
                    });
                } else {
                    toast({
                        variant: 'danger',
                        message: Intl.formatMessage({ id: 'TRANSLATOR.ERROR' }),
                    });
                }
            } else {
                toast({
                    variant: 'danger',
                    message: Intl.formatMessage({ id: 'CUSTOMERS.MODAL.APVINSTORE.ALERT.ALREADY.CREATED' }),
                });
            }
        } finally {
            setIsLoading(false);
        }
    };

    const handleRemovePackage = async (packageId: number) => {
        try {
            setIsLoading(true);
            const response = await deteleOnePackage(getValues('id'), packageId);
            if (response?.result?.id) {
                setOrder(response?.result);
                toast({
                    variant: 'success',
                    message: Intl.formatMessage({ id: 'CUSTOMER.MODAL.APVINSTORE.CONFIRM.DELETE.PACKAGE' }),
                });
            } else {
                toast({
                    variant: 'danger',
                    message: Intl.formatMessage({ id: 'TRANSLATOR.ERROR' }),
                });
            }
        } catch (error) {
            toast({
                variant: 'danger',
                message: Intl.formatMessage({ id: 'TRANSLATOR.ERROR' }),
            });
        } finally {
            setIsLoading(false);
        }
    };

    const handleAddOtherPackageWithoutTheList = () => {
        setShowAddPackageCustom(true);
    };

    return (
        <div className="mb-2">
            {stepperState.currentStep === 1 && (
                <APVInStorePackageSelect
                    onPackageSelect={handlePackageSelect}
                    selectedPackages={order?.orderPackages}
                    required={stepperState.currentStep === 1 && !order?.orderPackages?.length}
                />
            )}
            {isLoading && <Loader style={{ width: '5rem', height: '5rem' }} overlay />}
            <div className="d-flex justify-content-end mb-5">
                <Button variant="primary" onClick={handleAddOtherPackageWithoutTheList} disabled={showAddPackageCustom}>
                    <FormattedMessage id="CUSTOMER.MODAL.APVINSTORE.ADD.OTHER.PACKAGE" />
                </Button>
            </div>
            {order?.orderPackages &&
                order.orderPackages.map((pkg) => (
                    <div key={`package-${pkg.id}`}>
                        <div className="d-flex justify-content-between py-5">
                            <p className="mb-0 font-weight-bold d-flex align-items-center">{pkg.name}</p>
                            <div className="d-flex align-items-center">
                                <p className="mb-0 font-weight-bold">
                                    {pkg?.price === 0 ? (
                                        <FormattedMessage id="APV.OPERATION.FREE" />
                                    ) : (
                                        `${pkg.price?.toFixed(2)} € `
                                    )}
                                </p>
                                {!pkg?.mandatory && (
                                    <button
                                        type="button"
                                        className="btn btn-icon"
                                        onClick={() => handleRemovePackage(pkg?.id)}
                                        aria-label={`Remove ${pkg.name}`}
                                        title={Intl.formatMessage({ id: 'CUSTOMER.MODAL.APVINSTORE.DELETE.PACKAGE' })}
                                    >
                                        <i className="las la-trash fa-2x" />
                                    </button>
                                )}
                            </div>
                        </div>
                        <hr className="m-0" />
                    </div>
                ))}
            {showAddPackageCustom && (
                <APVInStoreAddPackageCustom
                    required={stepperState.currentStep === 1}
                    addPackage={handleAddingCustomPackage}
                    setShowAddPackageCustom={setShowAddPackageCustom}
                />
            )}
            {order?.orderPackages.length > 0 && (
                <>
                    <div className="d-flex justify-content-between mt-5">
                        <p className="font-weight-bold">
                            <FormattedMessage id="CUSTOMER.MODAL.APVINSTORE.TOTAL" />
                        </p>
                        {order.totalOrderAmount > 0 ? (
                            <p className="font-weight-bold">{order.totalOrderAmount.toFixed(2)} €</p>
                        ) : (
                            <FormattedMessage id="APV.OPERATION.FREE" />
                        )}
                    </div>
                    <hr className="m-0" />
                    <div className="d-flex justify-content-between mt-5">
                        <p className="font-weight-bold">
                            <FormattedMessage id="CUSTOMER.MODAL.APVINSTORE.TOTAL_VAT" />
                        </p>
                        {order.totalVatAmount > 0 ? (
                            <p className="font-weight-bold">{order.totalVatAmount.toFixed(2)} €</p>
                        ) : (
                            <FormattedMessage id="APV.OPERATION.FREE" />
                        )}
                    </div>
                    <hr className="m-0" />
                    <div className="d-flex justify-content-between mt-5">
                        <p className="font-weight-bold">
                            <FormattedMessage id="CUSTOMER.MODAL.APVINSTORE.TOTAL_TTC" />
                        </p>
                        {order.totalOrderAmountWithVat > 0 ? (
                            <p className="font-weight-bold">{order.totalOrderAmountWithVat.toFixed(2)} €</p>
                        ) : (
                            <FormattedMessage id="APV.OPERATION.FREE" />
                        )}
                    </div>
                    <hr className="m-0" />
                </>
            )}
        </div>
    );
};

export default APVInStorePackages;
