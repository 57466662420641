import { Divider } from 'primereact/divider';
import React, { useContext, useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';

import { ApiResponse } from '@app/helpers/AxiosHelper';

import { getOrder } from '@app/crud/apv/order.crud';
import { Order } from '@app/crud/apv/order.type';

import { useFetch } from '@app/hooks';
import { PackageRow } from '@app/pages/customers/Partials/PackageRow';

import Loader from '@app/partials/content/Loader';

import APVInStoreContext from '../APVInStoreContext';

const APVInStoreSummary = () => {
    const methods = useFormContext();
    const { getValues } = methods;
    const orderId = getValues('id');
    const { stepperState } = useContext(APVInStoreContext);

    const {
        fetch: fetchOrder,
        data: order,
        loading,
    } = useFetch<{ orderId: string }, ApiResponse<Order>, Order>({
        fetchAction: () => getOrder(orderId),
        resultInterceptor: (response: ApiResponse<Order>) => response?.result || null,
    });

    useEffect(() => {
        if (stepperState.currentStep === 3 && orderId) {
            fetchOrder(orderId);
        }
    }, [stepperState.currentStep]);

    return (
        <>
            {loading && <Loader style={{ width: '5rem', height: '5rem' }} overlay />}
            <div className="font-weight-semibold mt-11 mb-7">
                <FormattedMessage id="CUSTOMERS.MODAL.APVINSTORE.SUMMARY.VERIFY.CLIENT.INFO" />
            </div>
            <Container className="p-0">
                <Row>
                    <Col md={6} lg={6}>
                        <Container className="p-0">
                            <Row>
                                <Col md={3} lg={3}>
                                    <span className="font-weight-semibold light-grey-secondary">
                                        <FormattedMessage id="CUSTOMERS.MODAL.APVINSTORE.SUMMARY.CLIENT" />
                                    </span>
                                </Col>
                                <Col className="font-weight-semibold">
                                    {order.civility && `${order.civility}.`} {order.firstname} {order.lastname}
                                </Col>
                            </Row>
                            <Row>
                                <Col md={3} lg={3}>
                                    <span className="font-weight-semibold light-grey-secondary">
                                        <FormattedMessage id="CUSTOMERS.MODAL.APVINSTORE.SUMMARY.CLIENT.PHONE" />
                                    </span>
                                </Col>
                                <Col className="font-weight-semibold">{order.mobile}</Col>
                            </Row>
                            <Row>
                                <Col md={3} lg={3}>
                                    <span className="font-weight-semibold light-grey-secondary">
                                        <FormattedMessage id="CUSTOMERS.MODAL.APVINSTORE.SUMMARY.CLIENT.EMAIL" />
                                    </span>
                                </Col>
                                <Col className="font-weight-semibold">{order.email}</Col>
                            </Row>
                        </Container>
                    </Col>

                    <Col md={6} lg={6}>
                        <Row>
                            <Col md={3} lg={3}>
                                <span className="font-weight-semibold light-grey-secondary">
                                    <FormattedMessage id="CUSTOMERS.MODAL.APVINSTORE.SUMMARY.CLIENT.ADDRESS" />
                                </span>
                            </Col>
                            <Col className="font-weight-semibold">
                                <address>
                                    {order.address}
                                    <br />
                                    {order.zipcode} {order.city}
                                </address>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Divider type="solid" />
                <Row>
                    <Col md={6} lg={6}>
                        <Row>
                            <Col md={3} lg={3} className="font-weight-semibold">
                                <span className="font-weight-semibold light-grey-secondary">
                                    <FormattedMessage id="CUSTOMERS.MODAL.APVINSTORE.SUMMARY.CLIENT.DEALERSHIP" />
                                </span>
                            </Col>
                            <Col className="font-weight-semibold">{order.dealershipName}</Col>
                        </Row>
                    </Col>
                </Row>
                <Divider type="solid" />
                <div className="text-uppercase font-weight-semibold mb-7 mt-6">
                    <FormattedMessage id="CUSTOMERS.MODAL.APVINSTORE.SUMMARY.VERIFY.CLIENT.PRESTATIONS" />
                </div>
                {order.orderPackages?.map((orderPackage) => (
                    <PackageRow orderPackage={orderPackage} key={orderPackage.id} />
                ))}

                <Row className="mt-7">
                    <Col md={7} lg={7}>
                        <span className="font-weight-semibold" style={{ fontSize: '15px' }}>
                            <FormattedMessage id="CUSTOMERS.MODAL.APVINSTORE.SUMMARY.VERIFY.TOTAL" />
                        </span>
                    </Col>
                    <Col md={5} lg={5}>
                        <div className="justify-content-end d-flex font-weight-semibold " style={{ fontSize: '15px' }}>
                            {order.totalOrderAmount > 0 ? (
                                `${order.totalOrderAmount?.toFixed(2)} € `
                            ) : (
                                <FormattedMessage id="CUSTOMERS.MODAL.APVINSTORE.SUMMARY.FREE_PACKAGE" />
                            )}
                        </div>
                    </Col>
                </Row>
                <Divider type="solid" />

                <Row className="mt-7">
                    <Col md={7} lg={7}>
                        <span className="font-weight-semibold" style={{ fontSize: '15px' }}>
                            <FormattedMessage id="CUSTOMERS.MODAL.APVINSTORE.SUMMARY.VERIFY.TOTAL_VAT" />
                        </span>
                    </Col>
                    <Col md={5} lg={5}>
                        <div className="justify-content-end d-flex font-weight-semibold " style={{ fontSize: '15px' }}>
                            {order.totalVatAmount > 0 ? (
                                `${order.totalVatAmount?.toFixed(2)} € `
                            ) : (
                                <FormattedMessage id="CUSTOMERS.MODAL.APVINSTORE.SUMMARY.FREE_PACKAGE" />
                            )}
                        </div>
                    </Col>
                </Row>
                <Divider type="solid" />

                <Row className="mt-7">
                    <Col md={7} lg={7}>
                        <span className="font-weight-semibold" style={{ fontSize: '15px' }}>
                            <FormattedMessage id="CUSTOMERS.MODAL.APVINSTORE.SUMMARY.VERIFY.TOTAL_TTC" />
                        </span>
                    </Col>
                    <Col md={5} lg={5}>
                        <div className="justify-content-end d-flex font-weight-semibold " style={{ fontSize: '15px' }}>
                            {order.totalOrderAmountWithVat > 0 ? (
                                `${order.totalOrderAmountWithVat?.toFixed(2)} € `
                            ) : (
                                <FormattedMessage id="CUSTOMERS.MODAL.APVINSTORE.SUMMARY.FREE_PACKAGE" />
                            )}
                        </div>
                    </Col>
                </Row>
                <Divider type="solid" />
            </Container>
        </>
    );
};

export default APVInStoreSummary;
